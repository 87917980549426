import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CreateNetDto } from 'models/NetModels';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';

@autoinject
export class NetNew {
  private net: CreateNetDto = new CreateNetDto();
  private originalObject: CreateNetDto = new CreateNetDto();
  private saving: boolean = false;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private netService: NetService,
    private router: Router,
    private toastService: ToastService,
    private validationController: ValidationController,
    private validator: Validator,
    private utility: Utility,
    private dialogService: DialogService,
    private prompt: Prompt
  ) {
    ValidationRules.ensure('_netIdentifierNumeric')
      .required()
      .withMessage(i18n.tr('general.requiredField'))
      .ensure('ProducerId')
      .required()
      .ensure('CustomerId')
      .required()
      .withMessage('general.requiredField')
      .withMessage(i18n.tr('general.producerField'))
      .ensure('DeliveryDate')
      .required()
      .withMessage(i18n.tr('general.requiredField'))
      .on(this.net);
  }

  private activate(params) {
    if (params.CustomerId) {
      this.net.CustomerId = params.CustomerId;
      this.originalObject.CustomerId = params.CustomerId;
    }
  }

  private attached() {
    this.originalObject = JSON.parse(JSON.stringify(this.net));
  }

  private validateBeforeSave() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.net).then((result) => {
      const errors = result.filter((validateResult) => {
        return !validateResult.valid;
      });

      if (errors.length > 0) {
        this.validationController.validate();
      } else {
        this.netService
          .checkNetIdentifiers([this.net.NetIdentifier])
          .then((duplicates) => {
            if (!!duplicates && duplicates.length !== 0) {
              if (duplicates[0].IsCritical) {
                this.toastService.showError('net.createNetAlreadyExistingIdentifiers', null, '. ' + duplicates[0].Message);
              } else {
                let message = this.i18n.tr('net.createNetAlreadyExistingIdentifiersNotCritical') + '\n\n' + duplicates[0].Message;

                this.dialogService
                  .open({
                    viewModel: Prompt,
                    model: {
                      header: 'net.confirmCreateDuplicateNet',
                      message: message,
                      messagePreparsed: true,
                      actions: {
                        delete: { enabled: false },
                        save: { enabled: false },
                        cancel: { enabled: true, t: 'dialog.cancel' },
                        dontsave: { enabled: false },
                        continue: { enabled: true, t: 'net.createDuplicateNetAnyway' },
                      },
                    },
                  })
                  .whenClosed((response) => {
                    if (!response.wasCancelled) {
                      const result = response.output;
                      if (result === 'save') {
                        this.createNet();
                      }
                    }
                  });
              }
            } else {
              this.createNet();
            }
          })
          .catch((err) => this.errorService.handleError(err));
      }
    });
  }

  private createNet() {
    this.saving = true;
    // remove temporary value used to provide numeric inputs
    delete this.net['_netIdentifierNumeric'];
    delete this.net['_netIdentifierPrefix'];

    this.netService
      .createNet(this.net)
      .then((res) => {
        this.originalObject = null;
        this.toastService.showSuccess('net.created');
        this.router.navigateToRoute('net-detail', { Id: res.Id });
        this.saving = false;
      })
      .catch((err) => {
        this.saving = false;
        this.errorService.handleError(err);
      });
  }

  private canDeactivate() {
    // NOTE: Will react even if you hade made no changes.
    // See issue #303.

    if (!this.originalObject) {
      return true;
    }

    // fix dates
    this.net = this.utility.areEqualDateFix(this.net, [{ key: 'ShipmentDate' }]);
    this.originalObject = this.utility.areEqualDateFix(this.originalObject, [{ key: 'ShipmentDate' }]);

    if (!this.utility.areEqual(this.net, this.originalObject, true)) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.subFormOpenHeading',
            message: 'dialog.subFormOpenMessage',
            actions: {
              delete: { enabled: false },
              save: { enabled: false },
              cancel: { enabled: true, t: 'dialog.cancel' },
              dontsave: { enabled: false },
              continue: { enabled: true, t: 'dialog.continue' },
            },
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            return true;
          }
        });
    } else {
      return true;
    }
  }
}
